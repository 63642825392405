import React, { useState, useEffect } from "react";
import { Breadcrumb } from "../../shared/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { notify } from "../../utils/notify.js";
import { useLocalCartCount } from "../../context/LocalCartCount";
import { toast } from "react-toastify";
import { DeleteCartButton } from "../../shared/CheckoutPage/DeleteCartButton";
import { WishListButton } from "../../shared/CheckoutPage/WishListButton.jsx";
import { Counter } from "../../shared/CheckoutPage/Counter.jsx";
import { InfinitySpin } from 'react-loader-spinner';
import { ProductCard } from "../../shared/ProductCard";
import Slider from "react-slick"
import { fiveSlider } from "../../utils/slicksettings";
import { Wrapper } from "../../shared/Wrapper";
import { firstBreadCrumb, shipmentOne, recomendProduct, buyProducts } from "../../data/checkoutConfig";
import { CustomCheckbox } from "../../shared/CustomCheckbox";
import { FiMinus, FiPlus } from "react-icons/fi"
import { FaLongArrowAltRight, FaCheck } from "react-icons/fa";
import { useCart } from "../../context/CartContext";
import { apiClient } from "../../utils/apiWrapper.js";

export const Layout = ({ children, cartItems, cartSummaryFlag, removeItemsLoader }) => {


    const [isVisible, setIsVisible] = useState(false);
    const [listOfStore, setListOfStore] = useState([]);
    const navigate = useNavigate();
    const [summary, setSummary] = useState({});
    const [couponCodeValue, setCouponCodeValue] = useState();
    const [cardSummaryLoader, setCartSummaryLoader] = useState(false);

    const [totalOrderPrice, setTotalOrderPrice] = useState(0);
    const [discountPercent, setDiscountPercent] = useState(0);
    const [couponError, setCouponError] = useState("");
    const [couponCodeLoader, setCouponCodeLoader] = useState(false)


    useEffect(() => {
        handlerCartSummary();
    }, [cartSummaryFlag])


    const handlerCartSummary = async () => {

        setCartSummaryLoader(true)
        try {
            const response = await apiClient.get(`/cart-summary`);
            setSummary(response.data)
            setTotalOrderPrice(response.data.total_with_tax)
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setCartSummaryLoader(false)
        }
    }


    const handlerApplyCoupon = async (e) => {
        e.preventDefault();
        setDiscountPercent(0);
        setCouponCodeLoader(true);
        setCouponError("");
        if (discountPercent) {
            setDiscountPercent(0);
            setCouponCodeLoader(false);
            setCouponCodeValue("");
        }
        else {
            try {
                const response = await apiClient.post(`/apply-coupon`, {
                    "coupon_code": couponCodeValue,
                    "total_order_price": totalOrderPrice
                });
                setDiscountPercent(response.data.discount_amount);
                notify("Coupon Code Added.", "")

            }
            catch (error) {
                setCouponError("Coupon Code is not valid.")
                console.error('Error:', error);
            }
            finally {
                setCouponCodeLoader(false)
            }
        }

    }

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible((prev) => !prev);
        }, 1500);

        return () => clearInterval(interval);
    }, []);


    return (
        <React.Fragment>
            <Wrapper>
                <div className="grid grid-cols-12 gap-8">
                    <div className="col-span-9  mb-5">
                        {children}
                    </div>
                    <div className="col-span-3 mt-[65px]">
                        {cartItems && cartItems.length ? (<SideWrapper>
                            <form onSubmit={(e) => handlerApplyCoupon(e)}>
                                <h3 className="text-[#424242] text-xl font-semibold">Coupon Code</h3>
                                <input disabled={discountPercent} type="text" value={couponCodeValue} onChange={(e) => setCouponCodeValue(e.target.value)} placeholder="Enter Your Coupon Code" className="text-[#212121] rounded-[4px] border border-[#66666666] text-sm px-4 py-3 w-full mt-2" />
                                {couponError ? <span className="mt-2 block text-red-500 text-sm">{couponError}</span> : null}
                                {!discountPercent ?
                                    <button type="submit" className={`bg-primary text-center text-white block p-3 mt-2 w-full rounded-md font-semibold `} style={{ opacity: `${couponCodeLoader ? "0.5" : "1"}` }} disabled={couponCodeLoader}>{couponCodeLoader ? "Applying Coupon" : "Apply Coupon"}</button> :
                                    <button type="submit" className="bg-primary text-center text-white block p-3 mt-2 w-full rounded-md font-semibold">Remove Coupon</button>}
                            </form>
                        </SideWrapper>) : null}

                        {summary && summary.total_with_shipping ? (
                            <SideWrapper classes={"mt-4"}>
                                {!cardSummaryLoader ? <React.Fragment>
                                    {console.log(summary)}
                                    <h3 className="text-[#424242] text-[28px] font-semibold">Cart Summary</h3>
                                    <div className="w-full h-[1px] bg-gray-300 my-3"></div>
                                    {summary.subtotal ? (
                                        <div className="flex items-center justify-between text-[#030303] text-base my-3 mt-5">
                                            <span className="">Subtotal ({summary.item_count} items)</span>
                                            <span className="">{summary.currency_title} {(summary.subtotal).toFixed(2)}</span>
                                        </div>
                                    ) : null}

                                    {summary.savings ? (
                                        <div className="flex items-center justify-between text-primary font-bold text-base my-3">
                                            <span className="">Savings</span>
                                            <span className="">{summary.currency_title} {(summary.savings).toFixed(2)}</span>
                                        </div>
                                    ) : null}

                                    {summary.shipping_rate ? (
                                        <div className="flex items-center justify-between text-[#030303] text-base my-3">
                                            <span className="">Shipping & Handling</span>
                                            <span className="">{summary.currency_title} {(summary.shipping_rate)}</span>
                                        </div>
                                    ) : null}


                                    {summary.tax ? (
                                        <div className="flex items-center justify-between text-[#030303] text-base my-3">
                                            <span className="">Taxes</span>
                                            <span className="">{summary.currency_title} {(summary.tax).toFixed(2)}</span>
                                        </div>
                                    ) : null}

                                    {discountPercent ? (
                                        <div className="flex items-center justify-between text-[#030303] text-base my-3">
                                            <span className="">Coupon Discount <span className="text-primary text-sm font-semibold">({discountPercent}%)</span></span>
                                            <span className="">{summary.currency_title} -{(summary.total_with_shipping / discountPercent).toFixed(2)}</span>
                                        </div>
                                    ) : null}

                                    <div className="w-full h-[1px] bg-gray-300 my-3"></div>

                                    {discountPercent && summary.total_with_shipping ? (
                                        <div className="flex items-center justify-between text-[#030303] text-xl font-semibold my-3">
                                            <span className="">Total Amount</span>
                                            <span className="">{summary.currency_title}  {((summary.total_with_shipping) * ((100 - discountPercent) / 100)).toFixed(2)}</span>
                                        </div>
                                    ) : <div className="flex items-center justify-between text-[#030303] text-xl font-semibold my-3">
                                        <span className="">Total Amount</span>
                                        <span className="">{summary.currency_title} {(summary.total_with_shipping).toFixed(2)} </span>
                                    </div>}

                                    <button onClick={() => navigate("/review-checkout")} className="text-white text-base font-semibold text-center flex items-center justify-center py-3 px-3 bg-primary w-full rounded-md mt-5">
                                        <span className="mr-2">Confirm & Pay</span> <FaLongArrowAltRight />
                                    </button>

                                    <div className="text-[#64748B] text-xs flex items-center justify-center text-center flex-col mt-4">
                                        <p>By placing your order, you agree to Horeca store</p>
                                        <p><span className="font-bold">privacy notice</span> and <span className="font-bold">conditions of Use. </span></p>
                                    </div>

                                </React.Fragment> :
                                    <div className="w-full h-[350px] flex items-center justify-center ml-[-25px]">
                                        <InfinitySpin
                                            visible={true}
                                            height="120"
                                            width="120"
                                            color="#186737"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    </div>}
                            </SideWrapper>
                        ) : null}

                        <SideWrapper classes={"mt-4"}>
                            <div className="flex items-center ">
                                <img src={process.env.PUBLIC_URL + "/icons/certificate.png"} alt="" />
                                <span className="ml-2 text-primary text-base font-semibold">Horecastore protects your payment
                                    information</span>
                            </div>

                            <div className="mt-5">
                                <div className="flex items-center mt-2">
                                    <FaCheck color="black" size={20} />
                                    <span className="ml-3 text-[#64748B] text-base">Every transaction is secure and encrypted</span>
                                </div>
                                <div className="flex items-center  mt-2">
                                    <FaCheck color="black" size={30} />
                                    <span className="ml-3 text-[#64748B] text-base">We do not store your payment cards CVV sensuring your privacy</span>
                                </div>
                                <div className="flex items-center mt-2">
                                    <FaCheck color="black" size={20} />
                                    <span className="ml-3 text-[#64748B] text-base">Every transaction is secure and encrypted</span>
                                </div>
                                <div className="flex items-center mt-2">
                                    <FaCheck color="black" size={20} />
                                    <span className="ml-3 text-[#64748B] text-base">Every transaction is secure and encrypted</span>
                                </div>
                                <div className="flex items-center mt-2">
                                    <FaCheck color="black" size={20} />
                                    <span className="ml-3 text-[#64748B] text-base">Every transaction is secure and encrypted</span>
                                </div>
                                <div className="flex items-center mt-2 justify-center gap-4 mt-5">
                                    <img src={process.env.PUBLIC_URL + "/images/certificate/visa.png"} alt="" />
                                    <img src={process.env.PUBLIC_URL + "/images/certificate/master.png"} alt="" />
                                    <img src={process.env.PUBLIC_URL + "/images/certificate/pci.png"} alt="" />
                                    <img src={process.env.PUBLIC_URL + "/images/certificate/ssl.png"} alt="" />
                                </div>
                            </div>
                        </SideWrapper>

                        <SideWrapper classes={"mt-5"}>
                            <div className='flex items-center justify-between'>
                                <p
                                    className={`font-bold text-[#BF2536] text-base transition-opacity duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0'
                                        }`}
                                >Need Help Placing Order
                                </p>
                                <p className='text-[#4A4A4A] text-sm relative'> <span className='absolute  size-[8px] rounded-full bg-primary left-[-12px] top-1/2 translate-y-[-50%]'></span> Online Now</p>
                            </div>
                            <div className='rounded-[4px] bg-[#DEF9EC] flex items-center justify-between px-4 py-2 mt-4'>
                                <p className='text-[#4A4A4A] text-sm'>Our customer service will be glad to assist you</p>
                                <img className='size-[40px]' src={process.env.PUBLIC_URL + "/images/productDetails/specialist.png"} alt="" />
                            </div>

                            <div className='flex items-center justify-between mt-5'>
                                <Link className='text-[10px] text-primary font-semibold flex items-center mx-2' to="/"><img src={process.env.PUBLIC_URL + "/icons/chat.png"} className='mr-1' alt="" /> Chat Now</Link>
                                <Link className='text-[10px] text-primary font-semibold flex items-center mx-2' to="/"><img src={process.env.PUBLIC_URL + "/icons/phone-2.png"} className='mr-1' alt="" /> 800 HORECA (467322)</Link>
                                <Link className='text-[10px] text-primary font-semibold flex items-center mx-2' to="/"><img src={process.env.PUBLIC_URL + "/icons/email.png"} className='mr-1' alt="" /> Email Us</Link>
                            </div>
                        </SideWrapper>
                    </div>
                </div>
            </Wrapper>
        </React.Fragment>
    )
}


const SideWrapper = ({ children, classes }) => {
    return (
        <div className={`${classes} bg-[#F6F8FA] border-[#E2E8F033] border-2 rounded-[10px] p-5`}>{children}</div>
    )
}
